<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <h2>{{ engineTitle }} Unit Test Results</h2>
          </CCol>
          <CCol class="text-right">
            <CSelect
              :options="showChoices"
              :value.sync="showChoice"
              label="Show Tests:"
              horizontal
            />
          </CCol>
          <CCol class="text-right">
            <DownloadLink
              title="Download"
              :url="`/api/${engine.toLowerCase()}/clients/${clientCode}/test-results/${testRunId}/download`"
              class="btn btn-primary"
            >
              <MIcon name="fa-download" />&nbsp; Export
            </DownloadLink>
          </CCol>
        </CRow>
        <CRow v-if="testResultSummary.total > 0">
          <CCol>
            <CCallout
              color="info"
              :class="{ 'de-selected': showChoice !== 'ALL' }"
              class="pointer"
              @click.native="showChoice = 'ALL'"
            >
              <small class="h5">Total</small>
              <br />
              <strong class="h4">{{ testResultSummary.total }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout
              color="success"
              :class="{
                'de-selected': showChoice !== 'ALL' && showChoice !== 'VALID',
              }"
              class="pointer"
              @click.native="showChoice = 'VALID'"
            >
              <small class="h5">Passed</small>
              <br />
              <strong class="h4">{{ testResultSummary.passed }}</strong>
              <span
                v-if="testResultSummary.passedPct > 0"
                class="h5 text-muted pl-2"
                >({{ $format.percent(testResultSummary.passedPct) }})</span
              >
            </CCallout>
          </CCol>
          <CCol>
            <CCallout
              color="warning"
              :class="{
                'de-selected': showChoice !== 'ALL' && showChoice !== 'INVALID',
              }"
              class="pointer"
              @click.native="showChoice = 'INVALID'"
            >
              <small class="h5">Failed</small>
              <br />
              <strong class="h4">{{ testResultSummary.invalid }}</strong>
              <span
                v-if="testResultSummary.invalidPct > 0"
                class="h5 text-muted pl-2"
                >({{ $format.percent(testResultSummary.invalidPct) }})</span
              >
            </CCallout>
          </CCol>
          <CCol>
            <CCallout
              color="danger"
              :class="{
                'de-selected': showChoice !== 'ALL' && showChoice !== 'FAILED',
              }"
              class="pointer"
              @click.native="showChoice = 'FAILED'"
            >
              <small class="h5">Errors</small>
              <br />
              <strong class="h4">{{ testResultSummary.errors }}</strong>
              <span
                v-if="testResultSummary.errorPct > 0"
                class="h5 text-muted pl-2"
                >({{ $format.percent(testResultSummary.errorPct) }})</span
              >
            </CCallout>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="currentItems"
          :fields="currentFields"
          :loading="searchTestsActive"
          striped
          pagination
          size="sm"
          :items-per-page="50"
          sorter
          column-filter
          table-filter
        >
          <template #modelName-filter>
            <CSelect
              :options="modelFilterOptions"
              :value.sync="modelNameFilter"
              class="table-filter"
            />
          </template>
          <template #taxScenarioType-filter>
            <CSelect
              :options="scenarioFilterOptions"
              :value.sync="scenarioFilter"
              class="table-filter"
            />
          </template>
          <template #show_details="{ item, index }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="toggleDetails(item, index)"
                >{{ Boolean(item._toggled) ? 'Hide' : 'Show' }}</CButton
              >
            </td>
          </template>
          <template #details="{ item }">
            <CCollapse
              :show="Boolean(item._toggled)"
              :duration="collapseDuration"
            >
              <CRow>
                <CCol>
                  <div style="display: flex">
                    <div>
                      <CButton color="info" @click="handleShowInfo(item)">
                        <MIcon name="fa fa-search-plus" />
                      </CButton>
                    </div>
                    <div>
                      <ul>
                        <li v-for="msg in item.messages" :key="msg">
                          {{ msg }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCollapse>
          </template>
          <template #status="{ item }">
            <td>
              <span
                :class="
                  getStatusObject(item).variant
                    ? 'text-' + getStatusObject(item).variant
                    : ''
                "
              >
                <MIcon :name="getStatusObject(item).status" />
              </span>
            </td>
          </template>
          <template #clientCode="{ item }">
            <td>
              <router-link
                :to="{ name: 'view-client', params: { name: item.clientCode } }"
                >{{ item.clientCode }}</router-link
              >
            </td>
          </template>
          <template #title="{ item }">
            <td>
              <router-link
                v-if="item.taskId"
                :to="{ name: 'view-task', params: { id: item.taskId } }"
                >{{ item.title }}</router-link
              >
              <div v-else>{{ item.title }}</div>
            </td>
          </template>
          <template #recordStatus="{ item }">
            <td class="text-center">
              <CBadge :color="getStatusColor(item)">{{
                getStatusLabel(item)
              }}</CBadge>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            <CButton color="success" class="mr-1" @click="reviewTask('APPROVE')"
              >Approve</CButton
            >
            <CButton
              color="secondary"
              class="mr-1"
              @click="reviewTask('RESUBMIT')"
              >Resubmit and Regenerate</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <TestResultModal
      v-model="testResultModal"
      :test-result="currentTestResult"
      :engine="engine"
    />
  </div>
</template>
<script>
import DownloadLink from '@/components/DownloadLink';
import { mapGetters } from 'vuex';
import VERTEX_FIELDS from '@/../static/data/test-result-fields.json';
import SABRIX_FIELDS from '@/../static/data/sbx-result-fields.json';
import TestResultModal from '@/views/tasks/components/TestResultModal';

const SHOW_CHOICES = [
  {
    label: 'All Results',
    value: 'ALL',
  },
  {
    label: 'Only Valid',
    value: 'VALID',
  },
  {
    label: 'Only Failed',
    value: 'INVALID',
  },
  {
    label: 'Only Errors',
    value: 'FAILED',
  },
];

export default {
  name: 'TestResultsReviewCard',
  components: {
    TestResultModal,
    DownloadLink,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showChoice: 'ALL',
      collapseDuration: 0,
      currentTestResult: null,
      testResultModal: false,
      modelNameFilter: 'No Filter',
      scenarioFilter: 'No Filter',
    };
  },
  computed: {
    ...mapGetters('tests', [
      'testResults',
      'testResultColumns',
      'searchTestsActive',
      'testResultSummary',
      'activeModels',
      'activeScenarios',
    ]),
    clientCode() {
      return this.task.clientCode;
    },
    testRunId() {
      return (this.task.inputData || {}).testRunId || 0;
    },
    tests() {
      return this.testResults;
    },
    activeFields() {
      return this.testResultColumns;
    },
    itemCount() {
      return this.tests.length || 0;
    },
    modelFilterOptions() {
      var opts = ['No Filter', 'None'].concat(...this.activeModels);
      return opts.map((i) => {
        return { label: i, value: i };
      });
    },
    scenarioFilterOptions() {
      var opts = ['No Filter', 'None'].concat(...this.activeScenarios);
      return opts.map((i) => {
        return { label: i, value: i };
      });
    },
    currentItems() {
      return JSON.parse(JSON.stringify(this.tests))
        .filter((test) => {
          if (this.showChoice === 'ALL') return true;
          return this.showChoice === test.recordStatus;
        })
        .filter((test) => {
          var allow = true;
          if (this.modelNameFilter !== 'No Filter') {
            var modelName = test['modelName'];
            if (this.modelNameFilter === 'None') {
              allow = !modelName || modelName === '';
            } else {
              allow = modelName === this.modelNameFilter;
            }
          }

          if (allow && this.scenarioFilter !== 'No Filter') {
            var scenario = test['taxScenarioType'];
            if (this.scenarioFilter === 'None') {
              allow = !scenario || scenario === '';
            } else {
              allow = scenario === this.scenarioFilter;
            }
          }

          return allow;
        })
        .map((i) => {
          this.currentFields.forEach((f) => {
            if (!this._.has(i, f.key)) {
              i[f.key] = '';
            }
          });

          // this be a hack
          if (this._.has(i, 'DOC NUM')) {
            i['DOC NUM'] = i['DOC NUM'].substring(0, 12) + '...';
          }

          i._toggled = false;
          if (!this._.has(i, 'details')) {
            i._detailsLoading = false;
            i.details = null;
          }
          return i;
        });
    },
    engineFields() {
      switch (this.engine) {
        case 'SBX':
          return SABRIX_FIELDS;
        case 'VTX':
          return VERTEX_FIELDS;
        default:
          return SABRIX_FIELDS;
      }
    },
    currentFields() {
      if (this.itemCount < 1) {
        return [];
      }

      let fields = JSON.parse(JSON.stringify(this.engineFields));
      fields.forEach((field) => {
        if (this.activeFields.find((f) => f === field.key)) {
          field.visible = true;
        }
      });
      var result = fields.filter((f) => f.visible === true);

      result.unshift({
        label: 'Result',
        key: 'recordStatus',
        filter: false,
        visible: true,
      });

      result.unshift({
        label: '',
        key: 'show_details',
        filter: false,
        visible: true,
        sorter: false,
      });

      return result;
    },
    showChoices() {
      return SHOW_CHOICES;
    },
    engine() {
      return this.task.taskDefName.substring(0, 3).toUpperCase();
    },
    engineTitle() {
      switch (this.engine) {
        case 'SBX':
          return 'OneSource';
        case 'VTX':
          return 'Vertex';
        default:
          return 'Tax Engine';
      }
    },
  },
  mounted() {
    if (this.task) {
      this.$store.dispatch('tests/fetchTestResults', {
        clientCode: this.task.clientCode,
        testRunId: this.task.inputData.testRunId,
        type: this.engine,
      });
    }
  },
  methods: {
    exportResults() {
      this.$store.dispatch(
        'tests/exportTestResults',
        this.task.testRunId,
        this.engine
      );
    },
    getStatusColor(item) {
      switch (item.recordStatus) {
        case 'VALID':
          return 'success';
        case 'INVALID':
          return 'warning';
        case 'FAILED':
          return 'danger';
        default:
          return 'info';
      }
    },
    getStatusLabel(item) {
      switch (item.recordStatus) {
        case 'VALID':
          return 'Valid';
        case 'INVALID':
          return 'Failed';
        case 'FAILED':
          return 'Error';
        default:
          return item.recordStatus;
      }
    },
    completeTask(action, data) {
      let taskId = this.task.taskId;
      const payload = {
        taskId,
        data: data,
      };
      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Complete',
              text: 'This task has been successfully completed.',
            })
            .then(() => {
              this.$router.push({
                name: 'view-workflow',
                params: { id: this.task.workflowId },
              });
            });
        })
        .catch((e) => {
          this.$swal.fire({
            icon: 'error',
            title: 'ERROR',
            text:
              'Unable to complete this task due the following server error: ' +
              e.message,
          });
        });
    },
    reviewTask(value) {
      this.$emit('review', value);
    },
    toggleDetails(item) {
      //this.$set(this.currentItems[item.id], '_toggled', !item._toggled);
      item._toggled = !item._toggled;
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    handleShowInfo(item) {
      this.currentTestResult = item.resultId;
      this.testResultModal = true;
    },
  },
};
</script>
<style lang="scss">
.table-filter {
  width: 200px;
  &.form-group {
    margin-bottom: 0;
  }
  input,
  select {
    height: 30px;
  }
}

.pointer {
  cursor: pointer;
}
.de-selected {
  opacity: 0.5;
}
</style>
