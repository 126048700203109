<template>
  <dd v-if="!hasProcessedValue">
    <slot name="default">{{ displayValue }}</slot>
  </dd>
  <dd v-else>
    <slot name="default">
      <CRow>
        <CCol>
          {{ displayValue }}
          <em class="ml-1">(Formatted)</em>
        </CCol>
        <CCol>
          <CRow>
            <CCol class="col-md-4">
              <label>Raw</label>
            </CCol>
            <CCol class="col-md-8">
              <pre>{{ rawValue }}</pre>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </slot>
  </dd>
</template>
<script>
export default {
  name: 'TaskPropertyValue',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasProcessedValue() {
      return this.value.processed === true;
    },
    displayValue() {
      return this.value.value;
    },
    rawValue() {
      return this.value.raw;
    },
  },
};
</script>
