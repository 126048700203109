<template>
  <span class="file-link">
    <a v-if="!downloading" href="#" @click.prevent.stop="download()">
      <slot>{{ fileTitle }}</slot>
    </a>
    <template v-else>
      <span
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      ></span>
      <span class="file-link-downloading">
        <slot>{{ fileTitle }}</slot>
      </span>
    </template>
  </span>
</template>
<style lang="scss">
.file-link {
  &.btn {
    &.btn-primary {
      a {
        color: white;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
<script>
import api from '@/api';

export default {
  name: 'FileLink',
  props: {
    file: {
      type: Object,
      required: true,
    },
    defaultTitle: {
      type: String,
      required: false,
    },
    template: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      downloading: false,
    };
  },
  computed: {
    fileLink() {
      if (this.file.fileLink) {
        if (this.file.fileLink.endsWith('download')) {
          return this.file.fileLink;
        } else {
          return `${this.file.fileLink}/download`;
        }
      }
      return '#';
    },
    fileTitle() {
      return (
        this.file.displayTitle ||
        this.file.fileTitle ||
        this.file.title ||
        this.file.filename ||
        this.defaultTitle ||
        'Untitled File'
      );
    },
  },
  methods: {
    getFilename(disposition) {
      let parts = disposition.split('=');
      return parts[1].replaceAll(/"/gi, '');
    },
    download() {
      if (this.downloading || this.fileLink === '#') {
        return;
      }

      this.downloading = true;
      api.files
        .download(this.fileLink)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          const fileName = this.getFilename(
            res['headers']['content-disposition']
          );

          link.href = url;
          link.setAttribute('download', fileName);

          document.body.appendChild(link);

          link.click();
        })
        .catch((error) => {
          const statusCode = (error.response || { status: 500 }).status;
          let alert = {
            title: 'Error',
            text: 'Errors occurred trying to download the file. Please contact support if the problem persists.',
            icon: 'error',
          };
          switch (statusCode) {
            case 404:
              alert.title = 'Not Found';
              alert.text =
                'The resource could not be found. ' +
                'If it was generated you should review that previous tasks completed successfully.';
              break;
            case 403:
              alert.title = 'Forbidden';
              alert.text =
                'You do not have permission to access the requested resource.';
              break;
          }

          this.$swal.fire({
            title: alert.title || 'Error',
            text: alert.text,
            icon: alert.icon || 'error',
          });
        })
        .finally(() => {
          const self = this;
          setTimeout(() => {
            self.downloading = false;
          }, 1000);
        });
    },
  },
};
</script>
