<template>
  <div>
    <CRow v-if="template.isNotEmpty">
      <CCol>
        <CCard>
          <CCardHeader>
            <h2>Step 1: Download {{ template.templateTitle }}</h2>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CRow>
                  <CCol>
                    <h4>{{ template.templateTitle }}</h4>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    Download a copy of the
                    <FileLink
                      :file="{
                        fileLink: template.templateLink,
                        fileTitle: template.templateTitle,
                      }"
                      >template</FileLink
                    >&nbsp;in order to begin your work.
                  </CCol>
                </CRow>
              </CCol>
              <CCol class="text-right">
                <FileLink
                  :file="{
                    fileLink: template.templateLink,
                    fileTitle: template.templateTitle,
                  }"
                  class="btn btn-primary"
                  >Download</FileLink
                >
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <h2>
              {{ this.task.inputData ? 'Step 2: ' : '' }}Upload Completed File
            </h2>
          </CCardHeader>
          <CCardBody
            >When you have finished with the file, come back and drop it
            below.</CCardBody
          >
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <FileUploadBlock
          :task="task"
          @upload="signalUpload"
          @skip="$emit('skip')"
        />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import FileUploadBlock from '@/components/FileUploadBlock';
import FileLink from '@/components/FileLink';

export default {
  name: 'UploadCard',
  components: {
    FileUploadBlock,
    FileLink,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    template() {
      if (this.templateFile) {
        // TODO:
        return {
          isNotEmpty: false,
        };
      } else if (this.task.inputData.templateLink) {
        return {
          isNotEmpty: true,
          templateLink: this.task.inputData.templateLink.endsWith('download')
            ? this.task.inputData.templateLink
            : `${this.task.inputData.templateLink}/download`,
          templateTitle: this.task.inputData.templateTitle,
        };
      } else {
        return {
          isNotEmpty: false,
        };
      }
    },
    templateFile() {
      return this.task.inputFiles.find((f) => f.fileCategory === 'TEMPLATE');
    },
  },
  methods: {
    signalUpload(file) {
      this.$emit('upload', file);
    },
  },
};
</script>
