<template>
  <div>
    <div v-if="isLoading">
      <CCard>
        <CCardBody>
          <h2>
            Loading... Please wait...
            <CSpinner
              color="primary"
              style="
                width: 2rem;
                height: 2rem;
                border: 0.125em solid currentColor;
                border-right-color: transparent;
                margin-left: 10px;
              "
            />
          </h2>
        </CCardBody>
      </CCard>
    </div>
    <div v-else-if="hasLoadingError">
      <CCard>
        <CCardHeader>
          <h2>Error</h2>
        </CCardHeader>
        <CCardBody>
          An Error occurred while trying to load this task.
          <!--
          -->
          Please see your system administrator to resolve the issue.
        </CCardBody>
      </CCard>
    </div>
    <div v-else-if="!isDataFetched">
      <CCard>
        <CCardHeader>
          <h2>Task Not Found</h2>
        </CCardHeader>
        <CCardBody>This task could not be loaded.</CCardBody>
      </CCard>
    </div>
    <div v-else-if="task.locked" class="pt-5" style="height: 100%">
      <CRow class="text-center align-middle">
        <CCol>
          <CRow>
            <CCol>
              <div class="user-image float-right">
                <img src="img/avatars/6.jpg" class="c-avatar-img" />
              </div>
            </CCol>
            <CCol>
              <CRow class="pt-3">
                <CCol>This task is locked by</CCol>
              </CRow>
              <CRow class="pt-1">
                <CCol>
                  <h2 class="text-primary">{{ task.user }}</h2>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
        <CCol>
          <div class="float-left">
            <CRow class="pt-2">
              <CCol>{{ client ? client.fullName : '' }}</CCol>
            </CRow>
            <CRow class="pt-2">
              <CCol>
                <CButton color="primary">Request Access</CButton>
              </CCol>
            </CRow>
            <CRow class="pt-2">
              <CCol>Since {{ task.lockedTime }}</CCol>
            </CRow>
          </div>
        </CCol>
      </CRow>
    </div>
    <div v-else-if="showTaskReview(this.task)">
      <TaskReview :client="this.client" :task="this.task" />
    </div>
    <div v-else :class="{ 'is-loading': showSpinner }">
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader v-if="hasClientInformation">
              <h2>{{ client.clientCode }}: {{ client.projectName }}</h2>
            </CCardHeader>
            <!-- <CCardHeader v-else>
              <h2>{{ task.title }}</h2>
            </CCardHeader>-->
            <!-- <CCardBody v-if="currentProgress && currentProgress > 0"> -->
            <CCardBody v-if="currentProgress && currentProgress > 0">
              <CRow>
                <CCol>
                  <CProgress
                    :value="currentProgress"
                    height="30px"
                    color="info"
                  />
                </CCol>
              </CRow>
              <CRow>
                <!-- <CCol class="text-right">Project Completion: {{ currentProgress.toFixed(0) }}%</CCol> -->
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <ReviewCard
            v-if="task.taskDefName === 'output_review'"
            :task="task"
            @review="reviewTask"
            @skip="skipTask"
          />
          <TestSetReviewCard
            v-if="task.taskDefName.endsWith('generate_test_review')"
            :task="task"
            @skip="skipTask"
            @review="reviewTask"
          />
          <TestResultsReviewCard
            v-if="task.taskDefName.endsWith('test_result_review')"
            :task="task"
            @skip="skipTask"
            @review="reviewTask"
          />
          <UploadCard
            v-if="task.taskDefName === 'file_upload'"
            :task="task"
            @upload="uploadFile"
            @skip="skipTask"
          />
          <EnterpriseDefaultsCard
            v-if="task.taskDefName.endsWith('ent_defaults')"
            :task="task"
            @skip="skipTask"
          />
          <DefineCard
            v-if="task.taskDefName.endsWith('connectivity_input')"
            :task="task"
            @submit="submitConnectivity"
            @skip="skipTask"
          />
          <TestParametersCard
            v-if="task.taskDefName.endsWith('test_run_input')"
            :task="task"
            @submit="submitTestParameters"
            @skip="skipTask"
          />
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<style scoped>
.user-image {
  height: 100px;
  width: 100px;
}
</style>
<script>
import { formatDate } from '@/utils/format';
import { entityLoadingMixin } from '@/mixins';
import { mapGetters } from 'vuex';

import ReviewCard from '@/views/tasks/components/ReviewCard';
import UploadCard from '@/views/tasks/components/UploadCard';
import DefineCard from '@/views/tasks/components/DefineCard';
import EnterpriseDefaultsCard from '@/views/tasks/components/EnterpriseDefaultsCard';
import TaskReview from '@/views/tasks/TaskReview';
import TestParametersCard from '@/views/tasks/components/TestParametersCard';
import TestSetReviewCard from '@/views/tasks/components/TestSetReviewCard';
import TestResultsReviewCard from '@/views/tasks/components/TestResultsReviewCard';

export default {
  name: 'ViewTask',
  components: {
    ReviewCard,
    UploadCard,
    EnterpriseDefaultsCard,
    TaskReview,
    DefineCard,
    TestParametersCard,
    TestSetReviewCard,
    TestResultsReviewCard,
  },
  mixins: [entityLoadingMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dispatchPath: 'tasks/fetchTaskDetails',
      showSpinner: false,
    };
  },
  computed: {
    ...mapGetters('clients', ['allClients']),
    hasClientInformation() {
      return this.client && this.client.clientCode;
    },
    client() {
      if (!this.task || !this.task.clientCode) {
        return null;
      }

      return this.allClients.find(
        (client) => client.clientCode === this.task.clientCode
      );
    },
    task() {
      return this.fetchedData;
    },
    workflow() {
      return this.task.inputData ? this.task.inputData.workflow : null;
    },
    currentProgress() {
      return this.task.inputData ? this.task.inputData.progressPct : 0;
    },
    workflowParent() {
      return this.workflow ? this.workflow.parent : null;
    },
    taskIsCompleted() {
      if (!this.task) return false;
      return this.task.status === 'COMPLETED';
    },
  },
  methods: {
    formatDate,
    showTaskReview(task) {
      if (!task) {
        return false;
      }

      // console.log('Task', task);
      if (task.userTask) {
        switch (task.status) {
          case 'SCHEDULED':
          case 'IN_PROGRESS':
          case 'PENDING':
            return false;
        }
        // console.log(`User task with status: '${task.status}'`);
        return true;
      }

      if (!task.userTask || task.executed) {
        // console.log(`Executed: '${task.executed}'`);
        return true;
      }

      switch (this.task.status) {
        case 'SCHEDULED':
        case 'IN_PROGRESS':
          return false;
        default:
          // console.log(`Task with status: '${task.status}'`);
          return true;
      }
    },
    postDataSet() {
      if (this.fetchedData && this.fetchedData.correlationId) {
        this.$store.dispatch('clients/fetchClientDetails', {
          name: this.fetchedData.correlationId,
        });
      }
    },
    uploadFile(file) {
      this.showSpinner = true;
      this.$store
        .dispatch('tasks/submitFile', {
          id: this.task.taskId,
          file: file,
        })
        .then(() => {
          this.$swal
            .fire({
              title: 'Complete!',
              text: 'Your file was successfully uploaded!',
              icon: 'success',
            })
            .then(() => {
              this.$router.push({
                name: 'view-workflow',
                params: { id: this.task.workflowId },
              });
            });
        })
        .catch((e) => {
          var errorText = e.message;

          var errorTitle = 'Error';
          if (e.response) {
            const status = e.response.status;
            if (status === 400) {
              errorTitle = 'Invalid Request';
              var errors = JSON.parse(JSON.stringify(e.response.data));
              if (errors.messages && errors.messages.length > 0) {
                errorText = errors.messages[0];
              }
            }
          }

          this.$swal.fire({
            title: errorTitle,
            text: errorText,
            icon: 'error',
          });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    skipTask() {
      this.$store
        .dispatch('tasks/skipTask', this.task)
        .then(() => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Skipped',
              text: 'This task has been successfully skipped.',
            })
            .then(() => {
              this.$router.push({
                name: 'view-workflow',
                params: { id: this.task.workflowId },
              });
            });
        })
        .catch((e) => {
          this.$swal.fire({
            icon: 'error',
            title: 'ERROR',
            text:
              'Unable to skip this task due the following server error: ' +
              e.message,
          });
        });
    },
    completeTask(action, data) {
      let taskId = this.task.taskId;
      const payload = {
        taskId: this.task.taskId,
        data: data,
      };
      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Complete',
              text: 'This task has been successfully completed.',
            })
            .then(() => {
              this.$router.push({
                name: 'view-workflow',
                params: { id: this.task.workflowId },
              });
            });
        })
        .catch((e) => {
          this.$swal.fire({
            icon: 'error',
            title: 'ERROR',
            text:
              'Unable to complete this task due the following server error: ' +
              e.message,
          });
        });
    },
    reviewTask(option) {
      if (!option) {
        console.error('No option was selected');
        return;
      }
      this.completeTask('tasks/reviewTask', option);
    },
    submitConnectivity(conn) {
      this.completeTask('tasks/submitTaskData', conn);
    },
    submitTestParameters(params) {
      this.completeTask('tasks/submitTaskData', params);
    },
  },
};
</script>
