<template>
  <CCard>
    <CCardHeader>
      <h2>Files For Review</h2>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol>
          <FileLink :file="taskFile" />
          <!--
          <a
            :href="task.inputData.fileLink"
          >{{ task.inputData.fileTitle || task.inputData.filename || 'File Output'}}</a>
          -->
        </CCol>
        <CCol>
          <h5>Choose how to proceed after reviewing all of the files.</h5>
          <CRow>
            <CCol>
              <CSelect
                :options="reviewOptions"
                :value.sync="reviewOption"
                placeholder="Choose an option..."
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CButton
                color="primary"
                :disabled="!hasReviewOption"
                class="mr-1"
                @click="signalReview"
                >Submit</CButton
              >
              <CButton color="primary" @click="$router.back()">Back</CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import FileLink from '@/components/FileLink';
const REVIEW_OPTIONS = [
  {
    label: 'Approve',
    value: 'APPROVE',
  },
  {
    label: 'Resubmit and Regenerate',
    value: 'RESUBMIT',
  },
  {
    label: 'Escalate',
    value: 'ESCALATE',
  },
];

const FILELIST_FIELDS = [
  {
    key: 'fileType',
    label: '',
  },
  {
    key: 'fileName',
    label: 'File',
  },
  {
    key: 'updatedOn',
    label: 'Updated',
  },
  {
    key: 'actions',
    label: '',
  },
];

export default {
  name: 'ReviewCard',
  components: {
    FileLink,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reviewOption: null,
    };
  },
  computed: {
    taskFile() {
      return {
        fileId: this.task.inputData.fileId,
        fileLink: this.task.inputData.fileLink,
        fileTitle: this.task.inputData.fileTitle,
        filename: this.task.inputData.filename,
      };
    },
    fileFields() {
      return FILELIST_FIELDS;
    },
    fileList() {
      return this.task.inputFiles;
    },
    hasReviewOption() {
      return !!this.reviewOption;
    },
    reviewOptions() {
      return REVIEW_OPTIONS;
    },
  },
  methods: {
    signalReview(file) {
      if (this.reviewOption) {
        this.$emit('review', this.reviewOption);
      } else {
        console.error('No reviewOption...');
      }
    },
  },
};
</script>
