<template>
  <CCard v-if="hasTask">
    <CCardHeader>
      <h1>{{ detailsTitle }}</h1>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="hasDataFiles">
        <CCol>
          <h3>Task Files</h3>
          <div class="table-responsive">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col">Category</th>
                  <th scope="col">I/O</th>
                  <th scope="col">Title</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="file in inputFiles">
                  <th scope="row">
                    <CBadge :color="file.badge.color">{{
                      file.badge.text
                    }}</CBadge>
                  </th>
                  <td>Input</td>
                  <td>
                    <FileLink :file="file" />
                  </td>
                </tr>
                <tr v-for="file in outputFiles">
                  <th scope="row">
                    <CBadge :color="file.badge.color">{{
                      file.badge.text
                    }}</CBadge>
                  </th>
                  <td>Output</td>
                  <td>
                    <FileLink :file="file" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>
      <CRow v-if="isEscalated">
        <CCol>
          <h3>Escalation Details</h3>
          <hr />
          <CRow>
            <CCol xs="12" xl="6">
              <dl class="row">
                <dt class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  Incompletion Message
                </dt>
                <dd class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <pre class="diagnostic-output">{{
                    task.reasonForIncompletion
                  }}</pre>
                </dd>
                <template
                  v-if="
                    taskIssue.message &&
                    taskIssue.message != task.reasonForIncompletion
                  "
                >
                  <dt class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    Issue Message
                  </dt>
                  <dd class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <pre class="diagnostic-output">{{ taskIssue.message }}</pre>
                  </dd>
                </template>
                <template
                  v-if="!!taskIssue.details && !!taskIssue.details.rawMessage"
                >
                  <dt class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    Raw Message
                  </dt>
                  <dd class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <pre class="diagnostic-output">{{
                      taskIssue.details.rawMessage
                    }}</pre>
                  </dd>
                </template>
              </dl>
            </CCol>
            <!-- <CCol xl="6">
              <CButton color="primary" @click="advanceEscalation()">Advance</CButton>
            </CCol>-->
            <!-- <CCol xs="12" xl="6">
              <h4>Escalation Properties</h4>

              <dl v-if="taskIssueDetailsDataList.length > 0" class="row mt-2">
                <template v-for="entry in taskIssueDetailsDataList">
                  <dt class="col-xs-12 col-md-3">{{ entry.key }}</dt>
                  <TaskPropertyValue class="col-xs-12 col-md-9" :value="entry.value" />
                </template>
              </dl>
              <div v-else>
                <span>No additional data</span>
              </div>
            </CCol>-->
          </CRow>
          <CRow v-if="hasErrors">
            <CCol>
              <CTabs>
                <CTab
                  v-if="hasEscalationErrors"
                  title="Error Messages"
                  :active="hasEscalationErrors"
                >
                  <CDataTable
                    :items="escalationErrors"
                    :items-per-page="10"
                    size="sm"
                    sorter
                    pagination
                  />
                </CTab>
                <CTab
                  v-if="hasRecordErrors"
                  title="Record Errors"
                  :active="!hasEscalationErrors"
                >
                  <CDataTable
                    :items="recordErrors"
                    :items-per-page="10"
                    size="sm"
                    sorter
                    pagination
                  />
                </CTab>
              </CTabs>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow v-if="hasGenerateResult">
        <CCol>
          <CRow>
            <CCol>
              <h3>
                Generation Result
                <small
                  v-if="generateResult.count > 0"
                  class="test-small text-muted"
                  >({{ generateResult.count }})</small
                >
              </h3>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <div class="responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th colspan="2">Generation Messages</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="msg in generateResult.messages">
                      <td style="width: 40px">
                        <MIcon
                          :name="msg.level.name"
                          :class="msg.level.color"
                        />&nbsp;
                      </td>
                      <td>{{ msg.message }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <h3>Task Data</h3>
          <hr />
          <CTabs
            variant="pills"
            :vertical="{ navs: 'col-sm-3', content: 'col-sm-9' }"
          >
            <CTab>
              <span slot="title">
                <MIcon name="fa-info-circle" class="mr-2" />Details
              </span>
              <CRow>
                <CCol xs="12" xl="6">
                  <h4>General</h4>
                  <hr />
                  <dl class="row">
                    <template
                      v-for="entry in taskDetailsDataList"
                      :track-by="entry.key"
                    >
                      <dt class="col-xs-6 col-md-4">{{ entry.key }}</dt>
                      <dd class="col-xs-6 col-md-8">{{ entry.value }}</dd>
                    </template>
                  </dl>
                </CCol>
                <CCol xs="12" xl="6">
                  <h4>Task Times</h4>
                  <hr />
                  <dl class="row">
                    <dt class="col-xs-6 col-md-4">Scheduled At</dt>
                    <dd class="col-xs-6 col-md-8">{{ scheduledAt }}</dd>
                    <dt class="col-xs-6 col-md-4">Started At</dt>
                    <dd class="col-xs-6 col-md-8">{{ startedAt }}</dd>
                    <dt class="col-xs-6 col-md-4">Updated At</dt>
                    <dd class="col-xs-6 col-md-8">{{ updatedAt }}</dd>
                    <dt class="col-xs-6 col-md-4">Ended At</dt>
                    <dd class="col-xs-6 col-md-8">{{ endedAt }}</dd>
                    <dt v-if="isEscalated" class="col-xs-6 col-md-4">
                      Escalated At
                    </dt>
                    <dd v-if="isEscalated" class="col-xs-6 col-md-8">
                      {{ escalatedAt }}
                    </dd>
                  </dl>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <span slot="title">
                <MIcon name="fa-list" class="mr-2" />Input Data
              </span>
              <CRow>
                <CCol>
                  <dl class="row">
                    <template
                      v-for="entry in inputDataList"
                      :track-by="entry.key"
                    >
                      <dt class="col-xs-12 col-sm-6 col-md-4">
                        {{ entry.key }}
                      </dt>
                      <TaskPropertyValue
                        class="col-xs-12 col-sm-6 col-md-8"
                        :value="entry.value"
                      />
                    </template>
                  </dl>
                </CCol>
              </CRow>
            </CTab>
            <CTab :disabled="outputDataList.length < 1">
              <span slot="title">
                <MIcon name="fa-rss" class="mr-2" />Output Data
              </span>
              <CCol>
                <dl class="row">
                  <template
                    v-for="entry in outputDataList"
                    :track-by="entry.key"
                  >
                    <dt class="col-xs-12 col-sm-6 col-md-4">{{ entry.key }}</dt>
                    <TaskPropertyValue
                      class="col-xs-12 col-sm-6 col-md-8"
                      :value="entry.value"
                    />
                  </template>
                </dl>
              </CCol>
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter>
      <CRow>
        <CCol>
          <CButton color="primary" @click="$router.back()">Back</CButton>
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>
  <CCard v-else>
    <h1 class="text-error">No Task</h1>
  </CCard>
</template>
<style lang="scss">
pre.diagnostic-output {
  overflow: auto;
  min-height: 24px;
  max-height: 150px;
  width: 100%;
  white-space: pre-wrap;
  border: 1px solid #d8dbe0;
  background-color: #f2f4f7;
  padding: 1rem;
}
</style>
<script>
import WorkflowTask from '@/WorkflowTask';
import FileLink from '@/components/FileLink';
import TaskPropertyValue from '@/views/tasks/components/TaskPropertyValue';

const IGNORED_INPUT_PATTERNS = [
  new RegExp(
    '^(inputFiles|phase|rootFolder|doSkip|canSkip|internalOnly|asyncComplete)$',
    'i'
  ),
  new RegExp('^(task|identity|file|client).*$', 'i'),
  new RegExp('^.*(password|credentials).*$', 'i'),
];
const IGNORED_OUTPUT_PATTERNS = [
  new RegExp(
    '^(importResult|uploadUsername|bucketName|bucketPath|password|taskIssue)$',
    'i'
  ),
  new RegExp('^(file[^name]|bucket).*$', 'i'),
  new RegExp('^.*(password|credentials).*$', 'i'),
];

export default {
  name: 'TaskReview',
  components: {
    FileLink,
    TaskPropertyValue,
  },
  props: {
    client: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    task: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  computed: {
    hasClient() {
      return !!this.client && !!this.client.clientCode;
    },
    hasTask() {
      return !!this.task && !!this.task.taskId;
    },
    clientCode() {
      if (!this.hasClient) {
        return this.task.clientCode;
      }
      return this.client.clientCode || this.task.clientCode;
    },
    detailsTitle() {
      if (this.clientCode) {
        return `${this.clientCode}: ${this.task.title}`;
      }
      return this.task.title;
    },
    status() {
      return this.task.status;
    },
    isEscalated() {
      return this.task.escalated || false;
    },
    inputData() {
      return this.task.inputData || {};
    },
    outputData() {
      return this.task.outputData || {};
    },
    inputDataKeys() {
      return (Object.keys(this.inputData) || []).filter((key) => {
        for (const pattern of IGNORED_INPUT_PATTERNS) {
          if (pattern.test(key)) {
            return false;
          }
        }
        return true;
      });
    },
    outputDataKeys() {
      return (Object.keys(this.outputData) || []).filter((key) => {
        for (const pattern of IGNORED_OUTPUT_PATTERNS) {
          if (pattern.test(key)) {
            return false;
          }
        }
        return true;
      });
    },
    inputDataList() {
      let data = this.inputData;
      return this.inputDataKeys.map((k) => {
        return {
          key: k,
          value: this.processKeyValue(k, data[k]),
        };
      });
    },

    outputDataList() {
      let data = this.outputData;
      return this.outputDataKeys.map((k) => {
        return {
          key: k,
          value: this.processKeyValue(k, data[k]),
        };
      });
    },
    taskDetailsDataList() {
      var list = [];
      if (this.clientCode) {
        list.push({ key: 'Client', value: this.clientCode, link: '' });
      }
      list.push({
        key: 'Status',
        value: this.task.statusLabel,
      });

      list.push({
        key: 'Task Type',
        value: this.task.taskTypeLabel,
      });

      list.push({
        key: 'Task Reference',
        value: this.task.referenceTaskName,
      });

      if (!this.task.systemTask) {
        list.push({
          key: 'Worker',
          value: this.task.workerId,
        });
      }
      return list;
    },
    scheduledAt() {
      return this.$format.formatTimestamp(this.task.scheduledAt);
    },
    startedAt() {
      return this.$format.formatTimestamp(this.task.startedAt) || '--';
    },
    updatedAt() {
      return this.$format.formatTimestamp(this.task.updatedAt);
    },
    endedAt() {
      return this.$format.formatTimestamp(this.task.endedAt) || '--';
    },
    escalatedAt() {
      let t = this.task.escalatedAt;
      if (!t) {
        return '--';
      }
      if (typeof t === 'string' || t instanceof String) {
        return this.$format.formatTimestamp(t) || '--';
      } else if (typeof t === 'object' || t instanceof Date) {
        return this.$formatTimestamp(t) || '--';
      } else if (this.task.escalatedAt > 1000) {
        return (
          this.$format.formatTimestamp(new Date(this.task.escalatedAt)) || '--'
        );
      }
      return '--';
    },
    taskIssue() {
      if (this.isEscalated) {
        var issue = this.outputData.taskIssue;
        if (issue) {
          return JSON.parse(JSON.stringify(issue));
        }
      }
      return {};
    },
    taskIssueDetailsDataList() {
      let details = this.taskIssue.details || {};
      return Object.keys(details)
        .filter((k) => {
          if (k === 'rawMessage') {
            return false;
          }
          return true;
        })
        .map((key) => {
          return {
            key,
            value: this.processKeyValue(key, details[key]),
          };
        });
    },
    hasDataFiles() {
      return this.inputFiles.length > 0 || this.outputFiles.length > 0;
    },
    inputFiles() {
      return (this.task.inputFiles || []).map((f) => {
        f.badge = this.mapFileBadge(f);
        return f;
      });
    },
    outputFiles() {
      return (this.task.outputFiles || []).map((f) => {
        f.badge = this.mapFileBadge(f);
        return f;
      });
    },
    escalationErrors() {
      var importResult = this.outputData.importResult;
      if (!importResult) {
        return [];
      }

      return importResult.messages || [];
    },
    hasEscalationErrors() {
      return this.escalationErrors.length > 0;
    },
    recordErrors() {
      var importResult = this.outputData.importResult;
      if (!importResult) {
        return [];
      }

      return importResult.recordErrors || [];
    },
    hasRecordErrors() {
      return this.recordErrors.length > 0;
    },
    hasErrors() {
      return (
        this.hasEscalationErrors ||
        this.hasRecordErrors ||
        (this.outputData.importResult || {}).hasErrors === true
      );
    },
    hasGenerateResult() {
      return this.generateResult !== null;
    },
    generateResult() {
      if (
        !Object.prototype.hasOwnProperty.call(this.outputData, 'generateResult')
      ) {
        return null;
      }

      if (!this.outputData['generateResult']) {
        return null;
      }

      var result = JSON.parse(
        JSON.stringify(this.outputData['generateResult'])
      );
      if (
        !result.hasErrors &&
        !result.hasWarnings &&
        result.messages.length < 1
      ) {
        return null;
      }

      var messages = result.messages.map((o) => {
        var style = { name: 'fa-circle-thin', color: 'text-secondary' };
        switch (o.level) {
          case 'DEBUG':
            style.name = 'fa-bug';
            style.color = 'text-success';
            break;
          case 'INFO':
            style.name = 'fa-info-circle';
            style.color = 'text-info';
            break;
          case 'WARNING':
            style.name = 'fa-exclamation-triangle';
            style.color = 'text-warning';
            break;
          case 'ERROR':
            style.name = 'fa-exclamation-circle';
            style.color = 'text-danger';
        }
        o.level = style;
        return o;
      });
      return result;
    },
  },
  methods: {
    getMessageLevelIcon(level) {
      switch (level) {
        case 'INFO':
          return 'fa-info-circle';
      }
    },
    advanceEscalation() {
      // TODO: Force forward
    },
    processKeyValue(key, value) {
      let processedValue = {
        raw: value,
        value: value,
        processed: false,
      };

      if ((key.endsWith('At') || key.endsWith('Time')) && value > 1000) {
        processedValue.processed = true;
        processedValue.value = this.$format.formatTimestamp(new Date(value));
      }
      return processedValue;
    },
    mapFileBadge(f) {
      let badge = {
        color: 'secondary',
        text: f.category || 'OTHER',
      };

      switch (f.category) {
        case 'TEMPLATE':
          badge.color = 'info';
          break;
        case 'USER_UPLOAD':
          badge.color = 'success';
          break;
        case 'ARCHIVE':
          badge.color = 'warning';
          break;
      }

      return badge;
    },
  },
};
</script>
