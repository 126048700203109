<template>
  <CCard>
    <CCardBody
      ref="fileUpload"
      style="cursor: pointer; background: rgba(0, 0, 0, 0.03)"
      @click="uploadFile"
      @drop.prevent="setCurrentFile"
      @dragover.prevent
    >
      <input
        id="userFile"
        ref="userFile"
        type="file"
        style="visibility: hidden"
        @input="setCurrentFile"
      />
      <CRow>
        <CCol v-if="!currentFile">
          <h2 class="text-center">Drop files to Upload</h2>
        </CCol>
        <CCol v-else>
          <h2 class="text-center">
            <MIcon
              name="cil-file"
              class="text-center"
              style="height: 125px; width: 400px"
            />
          </h2>
        </CCol>
      </CRow>
      <CRow>
        <CCol v-if="!currentFile">
          <h6 class="text-center" style="color: rgba(0, 0, 0, 0.5)">
            or click here
          </h6>
        </CCol>
        <CCol v-else>
          <h4 class="text-center" style="color: rgba(0, 0, 0, 0.5)">
            {{ currentFile.name }}
          </h4>
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter>
      <slot name="footer">
        <CButton
          color="primary"
          class="mr-2"
          :disabled="uploadDisabled"
          @click="sendFile"
          >Upload</CButton
        >
        <CButton v-if="task.canSkip" class="mr-2" @click="$emit('skip')"
          >Skip</CButton
        >
        <CButton color="secondary" @click="cancel">Cancel</CButton>
      </slot>
    </CCardFooter>
  </CCard>
</template>
<script>
import _ from 'lodash';
export default {
  name: 'FileUploadBlock',
  // TODO: Probably need some sort of configuration properties here so we know which endpoint to send things to.
  props: {
    task: {
      type: Object,
      required: false,
      default() {
        return { canSkip: false };
      },
    },
    file: {
      type: File,
      required: false,
      default: null,
    },
    acceptedTypes: {
      type: Array,
      required: false,
      default: null,
    },
    submitEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      currentFile: this.file,
    };
  },
  computed: {
    getFilePreview() {
      if (!this.currentFile) return null;
      // console.log('mime: ' + this.currentFile.type);
      if (this.currentFile.type.startsWith('image/')) {
        return 'file-image';
      }
      switch (this.currentFile.type) {
        case 'application/json':
          return 'file-code';
        case 'application/excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return 'cil-file-excel';
        case 'text/csv':
          return 'file-csv';
        case 'text/xml':
          return 'file-code';
        case 'application/zip':
          return 'file-zip';
      }
      return 'file';
    },
    acceptableFileTypes() {
      if (this.task && this.task.inputData) {
        let types = this.task.inputData['acceptableFileTypes'];
        if (_.isArray(types)) {
          return types;
        } else if (types && types.length > 0) {
          return types.split(',').map((i) => i.trim());
        }
      }

      return this.acceptedTypes || [];
    },
    uploadDisabled() {
      return !(this.currentFile && this.submitEnabled);
    },
  },
  methods: {
    uploadFile() {
      this.$refs.userFile.click();
    },
    setCurrentFile(evt) {
      let f = this.$refs.userFile.files[0] || evt.dataTransfer.files[0];
      if (this.isAllowableFileType(f)) {
        this.currentFile = f;
        this.$emit('update', this.currentFile);
      } else {
        let msg = 'This file type is not supported.';
        if (this.acceptableFileTypes) {
          msg +=
            ' Please upload only files of the following types: ' +
            this.acceptableFileTypes.join(', ');
        }
        this.$swal.fire({
          title: 'Error',
          text: msg,
          icon: 'error',
        });
      }
    },
    sendFile() {
      this.$emit('upload', this.currentFile);
    },
    cancel() {
      this.currentFile = null;
      this.$router.go(-1);
    },
    isAllowableFileType(file) {
      if (!file) return false;
      if (!this.acceptableFileTypes) return true;
      if (this.acceptableFileTypes.length < 1) return true;
      if (this.hasValidExtension(file.name)) {
        return true;
      }
      return false;
    },
    hasValidExtension(fileName) {
      if (!this.acceptableFileTypes) return true;
      fileName = fileName.trim();
      let acceptedExtensions = [];
      this.acceptableFileTypes.forEach((type) => {
        switch (type) {
          case 'EXCEL':
            acceptedExtensions.push('.xls');
            acceptedExtensions.push('.xlsx');
            break;
          case 'CSV':
            acceptedExtensions.push('.csv');
            break;
          case 'TXT':
            acceptedExtensions.push('.txt');
            break;
          case 'ZIP':
            acceptedExtensions.push('.zip');
            break;
          case 'XML':
            acceptedExtensions.push('.xml');
            break;
          default:
            if (type) {
              acceptedExtensions.push(type);
            }
        }
      });
      if (acceptedExtensions.length < 1) {
        return true;
      }
      return !!acceptedExtensions.find((e) =>
        fileName.toLowerCase().endsWith(e.toLowerCase())
      );
    },
  },
};
</script>
