import _ from 'lodash';

export const mapFieldErrors = function (fields = []) {
  let retObj = {};
  fields.forEach((prop) => {
    const fieldError = {
      get() {
        const errorField = _.get(this.errorFields, prop);
        if (_.isNil(errorField)) {
          return {
            state: null,
            feedback: null,
          };
        }

        return {
          state: this.errorState(errorField),
          invalidFeedback: this.errorFeedback(errorField),
        };
      },
    };

    retObj[`${prop}Error`] = fieldError;
  });

  return retObj;
};

export const mapFormProps = function (
  props = [],
  { object, comparers, aliases } = {}
) {
  const hasAliases = !!aliases;
  const hasComparers = !!comparers;
  const hasSourceObject = !!object;

  return props.reduce((obj, prop) => {
    let alias = prop;
    if (hasAliases && _.has(aliases, prop)) {
      alias = _.get(aliases, prop);
    }

    const computed = {
      get() {
        if (hasSourceObject) {
          return this.getFieldValue(prop, _.get(this[object], alias));
        } else {
          return this.getFieldValue(prop, null);
        }
      },
      set(value) {
        let comparer = null;
        if (hasComparers) {
          comparer = _.get(comparers, prop);
        }

        if (hasSourceObject) {
          this.setPendingChange(
            prop,
            value,
            _.get(this[object], alias),
            comparer
          );
        } else {
          this.setPendingChange(prop, value, null, comparer);
        }
      },
    };

    obj[prop] = computed;

    const fieldError = {
      get() {
        const errorField = _.get(this.errorFields, prop);
        if (_.isNil(errorField)) {
          return {
            state: null,
            feedback: null,
          };
        }

        return {
          state: this.errorState(errorField),
          invalidFeedback: this.errorFeedback(errorField),
        };
      },
    };

    obj[`${prop}Error`] = fieldError;

    return obj;
  }, {});
};

export const formsMixin = {
  data() {
    return {
      isSaving: false,
      errors: {
        messages: [],
        fields: {},
      },
      pendingChanges: {},
      requiredFields: [],
      formMode: 'edit',
    };
  },
  computed: {
    hasRequiredFields() {
      for (let field of this.requiredFields) {
        let fieldValue = this[field];
        if (this.isEmptyValue(fieldValue)) {
          return false;
        }
      }
      return true;
    },
    hasPendingChanges() {
      return this.pendingFields.length > 0;
    },
    pendingFields() {
      return _.keys(this.pendingChanges) || [];
    },
    hasErrors() {
      return _.keys(this.errorFields).length > 0;
    },
    errorFields() {
      return _.get(this.errors, 'fields') || {};
    },
    errorMessages() {
      return _.get(this.errors, 'messages') || [];
    },
    hasErrorMessages() {
      return this.errorMessages.length > 0;
    },
  },
  methods: {
    hasError(fieldName) {
      return _.has(this.errorFields, fieldName);
    },
    isRequiredField(field) {
      return _.includes(this.requiredFields, field);
    },
    isEmptyValue(value) {
      if (_.isNil(value)) {
        return value !== false && value !== 0;
      } else if (Array.isArray(value)) {
        return _.size(value) > 0;
      } else if (`${value}`.trim().length === 0) {
        return true;
      }

      return false;
    },
    clearPendingChanges() {
      this.pendingChanges = {};
    },
    clearPendingChange(field) {
      this.pendingChanges = _.omit(this.pendingChanges, field);
    },
    clearErrors() {
      this.errors = {
        messages: [],
        fields: {},
      };
    },
    clearError(field) {
      if (_.has(this.errors.fields, field)) {
        this.errors.fields = _.omit(this.errors.fields, field);
      }
    },
    setFieldError(field, message) {
      if (!_.has(this.errors.fields, field)) {
        this.$set(this.errors.fields, field, [message]);
      } else {
        this.errors.fields[field] = [message];
      }
    },
    setPendingChange(field, value, originalValue, comparingFunction) {
      if (_.toLower(this.formMode) === 'create') {
        if (this.isEmptyValue(value) && _.has(this.pendingChanges, field)) {
          this.pendingChanges = _.omit(this.pendingChanges, field);
        } else if (!_.has(this.pendingChanges, field)) {
          this.$set(this.pendingChanges, field, value);
        } else {
          this.pendingChanges[field] = value;
        }
      } else {
        let pushChange = true;
        let equalToOriginal = false;
        if (!this.isEmptyValue(originalValue)) {
          if (comparingFunction) {
            pushChange = !comparingFunction(value, originalValue);
          } else {
            pushChange = originalValue !== value;
          }
          equalToOriginal = !pushChange;
        }

        if (pushChange) {
          if (!_.has(this.pendingChanges, field)) {
            this.$set(this.pendingChanges, field, value);
          } else {
            this.pendingChanges[field] = value;
          }
          if (this.isRequiredField(field) && this.isEmptyValue(value)) {
            this.setFieldError(
              field,
              'This field is required and cannot be empty'
            );
          } else {
            this.clearError(field);
          }
          return true;
        } else if (equalToOriginal) {
          this.clearPendingChange(field);
          this.clearError(field);
        }
      }

      return false;
    },
    getFieldValue(field, defaultValue) {
      if (_.toLower(this.formMode) === 'create') {
        return _.get(this.pendingChanges, field);
      }

      if (_.has(this.pendingChanges, field)) {
        return this.pendingChanges[field];
      } else {
        return defaultValue;
      }
    },
    errorState(field) {
      if (_.isNil(field) || _.size(field) === 0) {
        return null;
      }

      return false;
    },
    errorFeedback(field) {
      if (_.isNil(field) || _.size(field) === 0) {
        return null;
      }
      if (Array.isArray(field)) {
        return field[0];
      }

      return _.toString(field);
    },
    showSuccess(message) {
      if (!message || message.length == 0) {
        message = 'Updates successfully saved';
      }
      return this.$swal({
        title: 'Success',
        icon: 'success',
        text: message,
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    },
    handleApiError(err) {
      if (!err.response) {
        this.errors = {
          messages: [
            'Errors occurred communicating with the server, no changes were saved',
          ],
          fields: {},
        };

        return this.$swal({
          title: 'Communication Error',
          icon: 'warning',
          text: 'Errors occurred communicating with the server, no changes were saved.',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      } else {
        const res = err.response;
        if (res.status === 400) {
          this.errors = JSON.parse(JSON.stringify(res.data));
          return this.$swal
            .fire({
              title: 'Data Error',
              icon: 'error',
              text: 'The submitted data contains errors.  Please resolve the errors and re-submit.',
            })
            .then((resolve, reject) =>
              resolve({
                errors: this.errors,
              })
            );
        } else if (res.status === 401 || res.status === 403) {
          this.errors = {
            messages: [
              'You do not have sufficient permission to perform this action',
            ],
            fields: {},
          };

          return this.$swal({
            title: 'Prohibited',
            icon: 'error',
            text: 'You do not have sufficient permission to perform this action',
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        } else {
          self.errors = {
            messages: [
              'Errors occurred submitting data, no changes were saved',
            ],
            fields: {},
          };

          return this.$swal({
            title: 'Server Error',
            icon: 'error',
            text: 'Errors occurred submitting data, no changes were saved',
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        }
      }
    },
  },
};
