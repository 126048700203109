<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <h2>{{ engineName }} Unit Test Set Review: {{ runName }}</h2>
          </CCol>
          <CCol class="text-right">
            <CSelect
              :disabled="inclusion !== 'BOTH'"
              :options="showChoices"
              :value.sync="showChoice"
              label="Show Test Types:"
              horizontal
            />
          </CCol>
          <CCol col="1" class="text-right">
            <DownloadLink
              title="Download"
              :url="`/api/${engine.toLowerCase()}/clients/${
                task.clientCode
              }/test-transactions/download`"
              :params="{
                runType,
                generated: includeGenerated,
                custom: includeCustom,
                transactionDate,
              }"
              class="btn btn-primary"
            >
              <MIcon name="fa-download" />&nbsp; Export
            </DownloadLink>
          </CCol>
        </CRow>
        <CRow v-show="!searchTestsActive">
          <CCol>
            <CCallout color="primary">
              <small class="h5">Total Tests</small>
              <br />
              <strong class="h4">{{ totalCount }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="info">
              <small class="h5">Generated Tests</small>
              <br />
              <strong class="h4">{{ generatedCount }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="success">
              <small class="h5">Custom Tests</small>
              <br />
              <strong class="h4">{{ customCount }}</strong>
            </CCallout>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="currentItems"
          :fields="currentFields"
          :loading="searchTestsActive"
          striped
          pagination
          size="sm"
          :items-per-page="50"
          sorter
          column-filter
          table-filter
        >
          <template #over-table>
            <CRow>
              <CCol class="text-right"></CCol>
            </CRow>
          </template>
          <template #status="{ item }">
            <td>
              <span
                :class="
                  getStatusObject(item).variant
                    ? 'text-' + getStatusObject(item).variant
                    : ''
                "
              >
                <MIcon :name="getStatusObject(item).status" />
              </span>
            </td>
          </template>
          <template #clientCode="{ item }">
            <td>
              <router-link
                :to="{ name: 'view-client', params: { name: item.clientCode } }"
                >{{ item.clientCode }}</router-link
              >
            </td>
          </template>
          <template #title="{ item }">
            <td>
              <router-link
                v-if="item.taskId"
                :to="{ name: 'view-task', params: { id: item.taskId } }"
                >{{ item.title }}</router-link
              >
              <div v-else>{{ item.title }}</div>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            <CButton color="success" class="mr-1" @click="reviewTask('APPROVE')"
              >Approve</CButton
            >
            <CButton
              color="secondary"
              class="mr-1"
              @click="reviewTask('RESUBMIT')"
              >Resubmit and Regenerate</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DownloadLink from '@/components/DownloadLink';
import VERTEX_FIELDS from '@/../static/data/test-fields.json';
import SABRIX_FIELDS from '@/../static/data/sbx-result-fields.json';

const SHOW_CHOICES = [
  {
    label: 'All Tests',
    value: 'BOTH',
  },
  {
    label: 'Only Generated',
    value: 'GEN',
  },
  {
    label: 'Only Custom',
    value: 'UPL',
  },
];

export default {
  name: 'TestSetReviewCard',
  components: {
    DownloadLink,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showChoice: 'BOTH',
    };
  },
  computed: {
    ...mapGetters('tests', [
      'pendingTests',
      'pendingTestColumns',
      'searchTestsActive',
    ]),
    transactionDate() {
      if (!this.task || !this.task.inputData) {
        return null;
      } else {
        return (
          this.task.inputData['transactionDate'] || this.task.inputData['date']
        );
      }
    },
    includeCustom() {
      return this.inclusion === 'BOTH' || this.inclusion === 'CUSTOM';
    },
    includeGenerated() {
      return this.inclusion === 'BOTH' || this.inclusion === 'GENERATED';
    },
    inclusion() {
      if (!this.task || !this.task.inputData) {
        return 'BOTH';
      } else {
        return this.task.inputData['inclusion'] || 'BOTH';
      }
    },
    runName() {
      if (!this.task || !this.task.inputData) {
        return 'Test Run';
      } else {
        return this.task.inputData['runName'] || 'Test Run';
      }
    },
    runType() {
      if (!this.task || !this.task.inputData) {
        return 'JOURNEY';
      } else {
        return this.task.inputData['runType'] || 'JOURNEY';
      }
    },
    partition() {
      if (!this.task || !this.task.inputData) {
        return 'PRD';
      } else {
        return this.task.inputData['testPartition'] || 'PRD';
      }
    },
    tests() {
      return this.pendingTests;
    },
    totalCount() {
      return (this.tests || []).length;
    },
    generatedCount() {
      return (this.tests || []).filter((t) => t.recordType === 'GEN').length;
    },
    customCount() {
      return (this.tests || []).filter((t) => t.recordType === 'UPL').length;
    },
    activeFields() {
      return this.pendingTestColumns;
    },
    currentItems() {
      return JSON.parse(JSON.stringify(this.tests))
        .filter((test) => {
          if (this.showChoice === 'BOTH') return true;
          return this.showChoice === test.recordType;
        })
        .map((i) => {
          this.currentFields.forEach((f) => {
            if (!this._.has(i, f.key)) {
              i[f.key] = '';
            }
          });

          // this be a hack
          if (this._.has(i, 'DOC NUM')) {
            i['DOC NUM'] = i['DOC NUM'].substring(0, 8) + '...';
          }
          return i;
        });
    },
    engineName() {
      switch (this.engine) {
        case 'VTX':
          return 'Vertex';
        case 'SBX':
          return 'OneSource';
        default:
          return 'Tax Engine';
      }
    },
    engine() {
      return this.task.taskDefName.substring(0, 3).toUpperCase();
    },
    engineFields() {
      switch (this.engine) {
        case 'SBX':
          return SABRIX_FIELDS;
        case 'VTX':
          return VERTEX_FIELDS;
        default:
          return SABRIX_FIELDS;
      }
    },
    currentFields() {
      let fields = JSON.parse(JSON.stringify(this.engineFields));
      fields.forEach((field) => {
        if (this.activeFields.find((f) => f === field.key)) {
          field.visible = true;
        }
      });
      var result = fields.filter((f) => f.visible === true);
      return result;
    },
    showChoices() {
      switch (this.inclusion) {
        case 'CUSTOM':
          return [SHOW_CHOICES[2]];
        case 'GENERATED':
          return [SHOW_CHOICES[1]];
        default:
          return SHOW_CHOICES;
      }
    },
  },
  watch: {
    task(v) {
      if (v && v.clientCode) {
        var type = v.taskDefName.substring(0, 3).toUpperCase();
        this.$store.dispatch('tests/fetchPending', {
          clientCode: v.clientCode,
          type: type,
          transactionDate: v.inputData['transactionDate'],
          inclusion: v.inputData['inclusion'],
          runType: v.inputData['runType'],
          partition: v.inputData['testPartition'],
        });
      } else {
        // console.log('Not dispatching for task', v);
      }
    },
  },
  mounted() {
    if (this.task) {
      // console.log('Dispatching fetch pending tasks for client from mounted: ');
      this.$store.dispatch('tests/fetchPending', {
        clientCode: this.task.clientCode,
        type: this.engine,
        transactionDate: this.transactionDate,
        inclusion: this.inclusion,
        runType: this.runType,
        partition: this.partition,
      });
    } else {
      // console.log('Not dispatching for task', this.task);
    }
  },
  methods: {
    exportResults() {
      this.$store.dispatch('tests/exportTestSet', this.exportTestSet);
    },
    completeTask(action, data) {
      const payload = {
        taskId: this.task.taskId,
        data: data,
      };
      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Complete',
              text: 'This task has been successfully completed.',
            })
            .then(() => {
              this.$router.push({
                name: 'view-workflow',
                params: { id: this.task.workflowId },
              });
            });
        })
        .catch((e) => {
          this.$swal.fire({
            icon: 'error',
            title: 'ERROR',
            text:
              'Unable to complete this task due the following server error: ' +
              e.message,
          });
        });
    },
    reviewTask(value) {
      this.$emit('review', value);
    },
  },
};
</script>
