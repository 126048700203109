var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',[_c('h2',[_vm._v(_vm._s(_vm.engineName)+" Unit Test Set Review: "+_vm._s(_vm.runName))])]),_c('CCol',{staticClass:"text-right"},[_c('CSelect',{attrs:{"disabled":_vm.inclusion !== 'BOTH',"options":_vm.showChoices,"value":_vm.showChoice,"label":"Show Test Types:","horizontal":""},on:{"update:value":function($event){_vm.showChoice=$event}}})],1),_c('CCol',{staticClass:"text-right",attrs:{"col":"1"}},[_c('DownloadLink',{staticClass:"btn btn-primary",attrs:{"title":"Download","url":("/api/" + (_vm.engine.toLowerCase()) + "/clients/" + (_vm.task.clientCode) + "/test-transactions/download"),"params":{
              runType: _vm.runType,
              generated: _vm.includeGenerated,
              custom: _vm.includeCustom,
              transactionDate: _vm.transactionDate,
            }}},[_c('MIcon',{attrs:{"name":"fa-download"}}),_vm._v(" Export ")],1)],1)],1),_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searchTestsActive),expression:"!searchTestsActive"}]},[_c('CCol',[_c('CCallout',{attrs:{"color":"primary"}},[_c('small',{staticClass:"h5"},[_vm._v("Total Tests")]),_c('br'),_c('strong',{staticClass:"h4"},[_vm._v(_vm._s(_vm.totalCount))])])],1),_c('CCol',[_c('CCallout',{attrs:{"color":"info"}},[_c('small',{staticClass:"h5"},[_vm._v("Generated Tests")]),_c('br'),_c('strong',{staticClass:"h4"},[_vm._v(_vm._s(_vm.generatedCount))])])],1),_c('CCol',[_c('CCallout',{attrs:{"color":"success"}},[_c('small',{staticClass:"h5"},[_vm._v("Custom Tests")]),_c('br'),_c('strong',{staticClass:"h4"},[_vm._v(_vm._s(_vm.customCount))])])],1)],1)],1),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.currentItems,"fields":_vm.currentFields,"loading":_vm.searchTestsActive,"striped":"","pagination":"","size":"sm","items-per-page":50,"sorter":"","column-filter":"","table-filter":""},scopedSlots:_vm._u([{key:"over-table",fn:function(){return [_c('CRow',[_c('CCol',{staticClass:"text-right"})],1)]},proxy:true},{key:"status",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('span',{class:_vm.getStatusObject(item).variant
                  ? 'text-' + _vm.getStatusObject(item).variant
                  : ''},[_c('MIcon',{attrs:{"name":_vm.getStatusObject(item).status}})],1)])]}},{key:"clientCode",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{ name: 'view-client', params: { name: item.clientCode } }}},[_vm._v(_vm._s(item.clientCode))])],1)]}},{key:"title",fn:function(ref){
                  var item = ref.item;
return [_c('td',[(item.taskId)?_c('router-link',{attrs:{"to":{ name: 'view-task', params: { id: item.taskId } }}},[_vm._v(_vm._s(item.title))]):_c('div',[_vm._v(_vm._s(item.title))])],1)]}}])})],1)],1),_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',[_c('CButton',{staticClass:"mr-1",attrs:{"color":"success"},on:{"click":function($event){return _vm.reviewTask('APPROVE')}}},[_vm._v("Approve")]),_c('CButton',{staticClass:"mr-1",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.reviewTask('RESUBMIT')}}},[_vm._v("Resubmit and Regenerate")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }