<template>
  <CCard>
    <CCardHeader>
      <h2>{{ clientCode }}: Taxpayer Structure</h2>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol col="4">
          <CSelect
            label="Client Enterprise Model"
            :value.sync="selectedModelName"
            :options="enterpriseModelOptions"
            placeholder="Activate a Enterprise Model..."
          />
        </CCol>
        <CCol col="2">
          <DatePicker
            label="Start Date"
            :value="startDate"
            @change="
              (value) => {
                this.startDate = value;
              }
            "
          />
        </CCol>
        <CCol v-if="isOneSource" col="3">
          <CSelect
            label="Product Identifier"
            :value.sync="productIdentifier"
            :options="productIdentifiers"
          />
        </CCol>
        <CCol v-if="isOneSource" col="3">
          <CInput
            label="External Company ID Prepend"
            :value.sync="extCompanyIdPrepend"
            required
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <p>{{ description }}</p>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <table class="table table-sm table-fixed">
            <thead>
              <tr v-if="isVertex">
                <th>Filing Entity</th>
                <th>Customers</th>
                <th>Vendors</th>
                <th>Tax Category Mappings</th>
                <th>Tax Drivers</th>
                <th>Registrations</th>
                <th>Tax Rules</th>
              </tr>
              <tr v-else-if="isOneSource">
                <th>Filing Entity Type</th>
                <th>Tax Registrations Owner</th>
                <th>Customers Owner</th>
                <th>Product Mappings Owner</th>
                <th>TransEditors Owner</th>
                <th>ERP Code Rules Owner</th>
                <th>Allocations Owner</th>
                <th>Other Custom Data Owner</th>
                <th>FX and XML Owner</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!!selectedModel">
                <td v-for="attr in modelAttributes">
                  <CBadge :color="getColor(selectedModel[attr])">
                    {{ selectedModel[attr] }}
                  </CBadge>
                </td>
              </tr>
              <tr v-if="!!selectedModel && !!selectedModel.notes">
                <td colspan="7">{{ selectedModel.notes }}</td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CCardFooter>
        <CRow>
          <CCol>
            <CButton
              color="primary"
              :disabled="!selectedModelName || saving"
              @click.prevent="submit"
              >Submit</CButton
            >
          </CCol>
        </CRow>
      </CCardFooter>
    </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from 'vuex';
import apiConfig from '@/api/config';
import DatePicker from '@/components/DatePicker';
import { formatDate } from '@/utils/format';

const VERTEX_ATTR = [
  'filingEntityLevel',
  'customerOwner',
  'vendorOwner',
  'taxCategoryMappingOwner',
  'taxDriverOwner',
  'taxRegistrationOwner',
  'taxRuleOwner',
  'notes',
];
const SABRIX_ATTR = [
  'filingEntityLevel',
  'taxRegistrationOwner',
  'customersOwner',
  'productMappingsOwner',
  'transEditorOwner',
  'erpCodeRuleOwner',
  'allocationsOwner',
  'otherCustomDataOwner',
  'fxAndXmlOwner',
  'notes',
];

export default {
  name: 'EnterpriseDefaultsCard',
  components: {
    DatePicker,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    let d = {};
    if (this.task && this.task.outputData && this.task.outputData['previous']) {
      d = this.parsePreviousData(this.task.outputData['previous']);
    } else {
      d = {
        selectedModelName: null,
        startDate: new Date('2000-01-02'),
        productIdentifier: 'PRODUCT_CODE',
        extCompanyIdPrepend: null,
      };
    }
    d.saving = false;
    return d;
  },
  computed: {
    ...mapGetters('lists', ['enterpriseModels']),
    modelAttributes() {
      switch (this.engine) {
        case 'vtx':
          return VERTEX_ATTR;
        case 'sbx':
          return SABRIX_ATTR;
        default:
          return VERTEX_ATTR;
      }
    },
    submitPath() {
      return this.task.inputData.submitPath;
    },
    enterpriseModelOptions() {
      return (this.enterpriseModels || []).map((o) => {
        // TODO: This should be updated in the KB, but there is no 'display' title.
        var label = o.name;
        switch (o.name) {
          case '2-Tier ENT Cfgs':
            label = '2-Tier Enterprise Configurations';
            break;
          case '2-Tier ENT Cfgs CO Cstmrs':
            label = '2-Tier Enterprise Configurations, Company owned Customers';
            break;
          case '3-Tier ENT Cfgs REG Cstmr':
            label =
              '3-Tier Enterprise Configurations, Regional owned Customers';
            break;
          case '3-Tier REG Cfgs Max':
            label = '3-Tier Maximum Regional Configurations';
            break;
        }

        return { value: o.name, label: label, description: o.description };
      });
    },
    selectedModel() {
      if (!this.selectedModelName) {
        return null;
      }
      return this.enterpriseModels.find((o) => {
        return o.name == this.selectedModelName;
      });
    },
    clientCode() {
      return this.task.clientCode || this.task.inputData.clientCode;
    },
    isVertex() {
      return this.engine === 'vtx';
    },
    isOneSource() {
      return this.engine === 'sbx';
    },
    engine() {
      return this.task.taskDefName.substr(0, 3).toLowerCase();
    },
    productIdentifiers() {
      return [
        { label: 'Product Code', value: 'PRODUCT_CODE' },
        { label: 'Commodity Code', value: 'COMMODITY_CODE' },
      ];
    },
    description() {
      if (this.selectedModel) {
        return this.selectedModel.description;
      } else {
        return null;
      }
    },
  },
  watch: {
    task(v) {
      this.$store.dispatch('lists/fetchEnterpriseModels', this.engine);
      if (v && v.outputData && v.outputData['previous']) {
        var parsed = this.parsePreviousData(v.outputData['previous']);
        if (parsed) {
          this.selectedModelName = parsed['modelName'];
          this.startDate = parsed['startDate'];
          this.productIdentifier = parsed['productIdentifier'];
          this.extCompanyIdPrepend = parsed['extCompanyIdPrepend'];
        }
      }
    },
  },
  mounted() {
    if (!this.engine) return;
    this.$store.dispatch('lists/fetchEnterpriseModels', this.engine);
  },
  methods: {
    formatDate,
    parsePreviousData(prv) {
      if (!prv) {
        return null;
      }

      var prvStartDate = prv['startDate'];
      if (prvStartDate) {
        prvStartDate = prvStartDate.replace('T00:00:00.000+00:00', '');
      } else {
        new Date('2000-01-02');
      }

      return {
        selectedModelName: prv['modelName'],
        startDate: prvStartDate,
        productIdentifier: prv['productIdentifier'],
        extCompanyIdPrepend: prv['externalCompanyIdPrepend'],
      };
    },
    submit() {
      var req = {
        clientCode: this.clientCode,
        startDate: this.formatDate(this.startDate, 'yyyy-MM-dd'),
        modelName: this.selectedModelName,
      };

      if (this.isOneSource) {
        req.productIdentifier = this.productIdentifier;
        req.extCompanyIdPrepend = this.extCompanyIdPrepend;
      }

      this.saving = true;
      apiConfig.client
        .post(
          `/api/${this.engine}/clients/task/${this.task.taskId}/enterprise-defaults`,
          req
        )
        .then((res) => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Complete!',
              text: 'This task has been successfully completed.',
            })
            .then(() => {
              this.$router.push({
                name: 'view-workflow',
                params: { id: this.task.workflowId },
              });
            });
        })
        .catch((err) => {
          this.$swal.fire({
            title: 'ERROR',
            text: err.message,
            icon: 'error',
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getColor(value) {
      if (!value) return;
      switch (value.toUpperCase()) {
        case 'COMPANY':
          return 'primary';
        case 'DIVISION':
        case 'REGIONAL':
          return 'info';
        case 'DEPARTMENT':
        case 'ENTERPRISE':
          return 'secondary';
      }
    },
  },
};
</script>
