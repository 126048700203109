<template>
  <CButton
    v-if="button"
    :block="block"
    :color="color"
    :size="size"
    :disabled="disabled"
    class="c-loading-button"
    :class="{ 'c-loading-button-loading': downloading }"
    @click="download()"
  >
    <span
      v-show="downloading"
      class="c-loading-button-spinner spinner-border spinner-border-sm"
      role="status"
    ></span>
    <slot>{{ title }}</slot>
  </CButton>
  <span v-else class="download-link" :class="{ disabled: disabled }">
    <a v-if="!downloading" href="#" @click.prevent.stop="download()">
      <slot>{{ title }}</slot>
    </a>
    <template v-else>
      <span
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      ></span>
      <span class="download-link-downloading">
        <slot>{{ title }}</slot>
      </span>
    </template>
  </span>
</template>
<script>
import { client } from '@/AxiosClient';
export default {
  name: 'DownloadLink',
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    params: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    button: {
      type: Boolean,
      required: false,
      default: false,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      downloading: false,
    };
  },
  methods: {
    getFilename(disposition) {
      let parts = disposition.split('=');
      return parts[1].replaceAll(/"/gi, '');
    },
    download() {
      if (this.downloading || this.url === '#') {
        return;
      }

      this.downloading = true;
      const self = this;
      client
        .get(this.url, { responseType: 'blob', params: this.params || {} })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          const fileName = this.getFilename(
            res['headers']['content-disposition']
          );

          link.href = url;
          link.setAttribute('download', fileName);

          document.body.appendChild(link);

          link.click();
          self.$emit('completed', 'success');
        })
        .catch((error) => {
          console.log('Error', error);
          const statusCode = (error.response || { status: 500 }).status;
          let alert = {
            title: 'Error',
            text: 'Errors occurred trying to download the file. Please contact support if the problem persists.',
            icon: 'error',
          };
          switch (statusCode) {
            case 404:
              alert.title = 'Not Found';
              alert.text =
                'The resource could not be found. ' +
                'If it was generated you should review that previous tasks completed successfully.';
              break;
            case 403:
              alert.title = 'Forbidden';
              alert.text =
                'You do not have permission to access the requested resource.';
              break;
          }

          self.$swal
            .fire({
              title: alert.title || 'Error',
              text: alert.text,
              icon: alert.icon || 'error',
            })
            .then(() => {
              self.$emit('completed', 'error');
            });
        })
        .finally(() => {
          setTimeout(() => {
            self.downloading = false;
          }, 1000);
        });
    },
  },
};
</script>
<style lang="scss">
.download-link {
  &.btn {
    &.btn-primary {
      a {
        color: white;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
