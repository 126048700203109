<template>
  <CCard>
    <CCardHeader>
      <h2>Test Run Parameters</h2>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol>
          <CInput label="Test Run Name" :value.sync="testRun.name" required />
        </CCol>
        <CCol>
          <DatePicker
            v-model="testRun.date"
            label="Transaction Date"
            opens="left"
            required
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol col="6">
          <CSelect
            label="Test Type"
            :options="testRunTypes"
            :value.sync="testRun.type"
            required
          />
        </CCol>
        <CCol v-if="isSbx && isAdHocTest" col="6">
          <CSelect
            label="Partition"
            :options="partitions"
            :value.sync="testRun.partition"
            required
          />
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter>
      <CRow>
        <CCol>
          <CButton
            color="primary"
            :disabled="!canSubmit"
            @click.prevent="submit"
            >Submit</CButton
          >
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>
</template>
<script>
import DatePicker from '@/components/DatePicker';

const TEST_RUN_TYPES = [
  {
    text: 'Generated and Custom',
    value: 'BOTH',
  },
  {
    text: 'Generated Only',
    value: 'GENERATED',
  },
  {
    text: 'Custom Only',
    value: 'CUSTOM',
  },
];

export default {
  name: 'TestParametersCard',
  components: {
    DatePicker,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      testRun: {
        name: null,
        date: new Date(),
        type: 'BOTH',
        partition: 'PRD',
      },
    };
  },
  computed: {
    isSbx() {
      return (
        this.task.taskDefName.substr(0, 3).toLowerCase() === 'sbx' ||
        this.task.taskDefName.toLowerCase().includes('sbx')
      );
    },
    isAdHocTest() {
      if (!this.task.inputData || !this.task.inputData['runType']) {
        return this.task.taskDefName.toLowerCase().includes('adhoc');
      } else {
        let runType = `${this.task.inputData['runType'] || ''}`;
        return runType.toLowerCase() === 'ad_hoc';
      }
    },
    hasTestRun() {
      return !!this.testRun;
    },
    hasTestRunName() {
      if (this.hasTestRun) {
        return !!this.testRun.name;
      }
      return false;
    },
    hasTestRunDate() {
      if (this.hasTestRun) {
        return !!this.testRun.date;
      }
      return false;
    },
    hasTestRunType() {
      if (this.hasTestRun) {
        return !!this.testRun.type;
      }
      return false;
    },
    canSubmit() {
      return this.hasTestRunName && this.hasTestRunDate && this.hasTestRunType;
    },
    testRunTypes() {
      return TEST_RUN_TYPES;
    },
    partitions() {
      return Object.freeze([
        {
          value: 'PRD',
          text: 'Production',
        },
        {
          value: 'DEV',
          text: 'Development',
        },
        {
          value: 'LAB',
          text: 'Lab / Test',
        },
      ]);
    },
  },
  methods: {
    submit() {
      if (!this.canSubmit) return;
      this.$emit('submit', {
        name: this.testRun.name,
        date: this.$format.formatDate(this.testRun.date, 'yyyy-MM-dd'),
        type: this.testRun.type,
      });
    },
  },
};
</script>
