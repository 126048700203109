<template>
  <CCard>
    <CCardHeader>
      <h2>Tax Engine Connection</h2>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol>
          <CInput
            label="Tax Engine URL"
            :value.sync="connection.taxEngineUrl"
            required
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CInput
            label="Username"
            :value.sync="connection.username"
            :required="authIsRequired"
          />
        </CCol>
        <CCol>
          <CInput
            label="Password"
            type="password"
            :value.sync="connection.password"
            :required="authIsRequired"
          />
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter>
      <CRow>
        <CCol>
          <CButton
            color="primary"
            :disabled="!(canSubmit && testSuccess)"
            @click.prevent="submit"
            >Submit</CButton
          >
          <MButton
            color="info"
            :disabled="!canTestConnection"
            :active="isCurrentlyTesting"
            class="ml-2"
            @click="testConnection"
            >Test Connection</MButton
          >
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>
</template>
<script>
import apiConfig from '@/api/config';
import MButton from '@/components/MButton';

export default {
  name: 'DefineCard',
  components: {
    MButton,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      testSuccess: false,
      isCurrentlyTesting: false,
      connection: {
        taxEngineUrl: null,
        taxEngineType: null,
        username: null,
        password: null,
      },
    };
  },
  computed: {
    canTestConnection() {
      if (!this.hasTaskEngineUrl) {
        return false;
      }

      if (this.authIsRequired) {
        return this.hasUsername && this.hasPassword;
      }

      return true;
    },
    authIsRequired() {
      if (this.formMode === 'sbx') {
        return this.connection.taxEngineType === 'SABRIX_SOAP';
      }

      return true;
    },
    formMode() {
      if (this.task) {
        // This should be the _preferred_ way of getting the information in the future.
        if (this.task.inputData && this.task.inputData['taxEngineType']) {
          switch (this.task.inputData['taxEngineType'].toLowerCase()) {
            case 'vtx':
              return 'vtx';
            case 'sbx':
              return 'sbx';
            default:
            // Do nothing, let the rest of this method do the work...
          }
        }

        if (this.task.taskDefName) {
          var taskDefName = this.task.taskDefName.toLowerCase();
          if (
            taskDefName.startsWith('sbx') ||
            taskDefName.startsWith('idt4sbx')
          ) {
            return 'sbx';
          } else if (
            taskDefName.startsWith('vtx') ||
            taskDefName.startsWith('idt4vtx')
          ) {
            return 'vtx';
          }
        }
      }

      return 'vtx';
    },
    hasConnection() {
      return !!this.connection;
    },
    hasTaskEngineUrl() {
      if (this.hasConnection) {
        return !!this.connection.taxEngineUrl;
      }
      return false;
    },
    hasUsername() {
      if (this.hasConnection) {
        return !!this.connection.username;
      }
      return false;
    },
    hasPassword() {
      if (this.hasConnection) {
        return !!this.connection.password;
      }
      return false;
    },
    canSubmit() {
      if (!this.hasTaskEngineUrl) {
        return false;
      }

      if (this.authIsRequired && !(this.hasUsername && this.hasPassword)) {
        return false;
      }
      return this.testSuccess;
    },
  },
  watch: {
    connection(newValue, prvValue) {
      if (this.testSuccess && newValue != prvValue) {
        if (!newValue || !prvValue) {
          this.testSuccess = false;
        } else if (
          newValue.taxEngineUrl !== prvValue.taxEngineUrl ||
          newValue.username !== prvValue.username ||
          newValue.password !== prvValue.password
        ) {
          this.testSuccess = false;
        }
      }
    },
  },
  methods: {
    submit() {
      if (!this.canSubmit) return;
      this.$emit('submit', {
        taxEngineUrl: this.connection.taxEngineUrl,
        taxEngineType: this.connection.taxEngineType,
        username: this.connection.username,
        password: this.connection.password,
      });
    },
    testConnection() {
      if (this.isCurrentlyTesting) {
        return;
      }
      this.isCurrentlyTesting = true;
      let info = {
        url: this.connection.taxEngineUrl,
        username: this.connection.username,
        password: this.connection.password,
      };

      var connTestUrl = `/api/${this.formMode}/testing/test-connection`;

      apiConfig.client
        .post(connTestUrl, info)
        .then((res) => {
          this.testSuccess = true;
          this.$swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'We were successfully able to connect to the Tax Engine.',
          });
        })
        .catch((err) => {
          this.testSuccess = false;
          let message =
            'Unable to connect to the Tax Engine with the provided connection info.';
          if (err.response.data && err.response.data.message) {
            message = err.response.data.message;
          }
          this.$swal.fire({
            title: 'ERROR',
            text: message,
            icon: 'error',
          });
        })
        .finally(() => {
          this.isCurrentlyTesting = false;
        });
    },
  },
};
</script>
