<template>
  <CButton class="mr-1" :color="color" @click="$emit('click')">
    <span
      v-if="active"
      class="spinner-border spinner-border-sm mr-2"
      role="status"
      aria-hidden="true"
    ></span>
    <slot>{{ currentLabel }}</slot>
  </CButton>
</template>
<script>
export default {
  name: 'MButton',
  props: {
    label: {
      type: [Object, String],
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  computed: {
    currentLabel() {
      if (typeof this.label !== 'object') return this.label;
      return this.active ? this.label.active : this.label.dormant;
    },
  },
};
</script>
